import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { components } from "../slices";
import Layout from "../components/Layout";
import Content from "../components/Content";
import Contact from "../components/Contact";
import "../styles/page.scss";
import { ParallaxProvider } from "react-scroll-parallax";
import BreakingNews from "../components/BreakingNews";
import BackgroundSlides from "../components/BackgroundSlides";
import favicon from "../assets/favicon.png";
import defaultImage from "../assets/default-image.jpg";

const News = ({ location, data }) => {
  const page = data.prismicNews;
  const lang = page.lang.slice(0, 2);

  return (
    <ParallaxProvider>
      <Layout page={page} type="page">
        <main>
          <BackgroundSlides
            lang={lang}
            location={location}
            color={page.data.page_color}
          />
          <Content color={page.data.page_color}>
            <BreakingNews
              color={page.data.page_color}
              visible={true}
              entrance={true}
            />
            <div className="news-header">
              <h1 className="news-title">{page.data.title.text}</h1>
              {page.data.date && (
                <div className="news-date">
                  {page.data.date.toString().substring(8, 10)} /{" "}
                  {page.data.date.toString().substring(5, 7)} /{" "}
                  {page.data.date.toString().substring(0, 4)}
                </div>
              )}
            </div>
            <SliceZone slices={page.data.body} components={components} />
          </Content>
          <Contact lang={page.lang} />
        </main>
      </Layout>
    </ParallaxProvider>
  );
};

export default News;

export const Head = ({ data }) => (
  <>
    <title>{`${data.prismicNews.data.title.text.toLowerCase()} → the sun project`}</title>
    <meta
      name="description"
      content={data.prismicNews.data.seo_description.text}
    />
    <meta name="keywords" content={data.prismicNews.data.seo_keywords.text} />
    <meta name="author" content="the sun project" />

    <meta
      property="og:title"
      content={`${data.prismicNews.data.title.text.toLowerCase()} → the sun project`}
    />
    <meta property="og:site_name" content="the sun project" />
    <meta
      property="og:description"
      content={data.prismicNews.data.seo_description.text}
    />
    <meta
      property="og:image"
      content={`https://thesunproject.fr${defaultImage}`}
    />
    <meta property="og:locale" content={data.prismicNews.lang} />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="the sun project" />
    <meta
      name="twitter:title"
      content={`${data.prismicNews.data.title.text.toLowerCase()} → the sun project`}
    />
    <meta
      name="twitter:description"
      content={data.prismicNews.data.seo_description.text}
    />
    <meta
      name="twitter:image"
      content={`https://thesunproject.fr${defaultImage}`}
    />

    <link rel="icon" type="image/png" href={favicon} />
  </>
);

export const pageQuery = graphql`
  query NewsBySlug($uid: String, $lang: String) {
    prismicNews(id: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      type
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        seo_keywords {
          text
        }
        seo_description {
          text
        }
        page_color
        date
        body {
          ...NewsDataBodySectionTitle
          ...NewsDataBodyTextBox
          ...NewsDataBodyListBox
          ...NewsDataBodyImageBox
          ...NewsDataBodyEmbedBox
          ...NewsDataBodyImageGrid
          ...NewsDataBodyLogoBox
          ...NewsDataBodySlideshow
          ...NewsDataBodyTextImageBox
          ...NewsDataBodyCreationSlideshow
          ...NewsDataBodyEmbedMultiple
          ...NewsDataBodyFaq
        }
      }
    }
  }
`;
